<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">培训管理</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">练习题库配置</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl ">
                    <div class="searchbox">
                        <div title="题库名称" class="searchboxItem ci-full">
                            <span class="itemLabel">题库名称:</span>
                            <el-input size="small" v-model="searchForm.questionBankName" clearable placeholder="请输入题库名称"></el-input>
                        </div>
                        <div title="培训任务名称" class="searchboxItem ci-full">
                            <span class="itemLabel" style="min-width: 8em;">培训任务名称:</span>
                            <el-input size="small" v-model="searchForm.projectName" clearable placeholder="请输入培训任务名称"></el-input>
                            <!-- <el-select
                                v-model="searchForm.projectName"
                                placeholder="请选择培训任务"
                                remote
                                size="small"
                                clearable
                                :remote-method="getProjectList"
                                @visible-change="clearProjectList">
                                <template>
                                    <div class="select-header">
                                        <span style="color: #8492a6;float: left;font-size: 13px;width: 150px;">班级编号</span>
                                        <span style="color: #8492a6;float: left;font-size: 13px;margin-left: 50px;width: 250px;">班级名称</span>
                                    </div>
                                    <div class="select-header">
                                        <el-input
                                            v-model="searchProjectForm.projectCode"
                                            v-on:input="getProjectList"
                                            type="text"
                                            size="small"
                                            placeholder="班级编号"
                                            clearable
                                            style="width: 150px"
                                        />
                                        <el-input
                                            v-model="searchProjectForm.projectName"
                                            v-on:input="getProjectList"
                                            type="text"
                                            size="small"
                                            placeholder="请输入班级名称"
                                            clearable
                                            style="margin-left: 50px; width: 250px"
                                        />
                                    </div>
                                </template>
                                <el-option
                                    v-for="(item, index) in projectList"
                                    :key="index"
                                    :label="item.projectName"
                                    :value="item.projectName">
                                    <span style="width: 150px; font-size: 13px">{{item.projectCode}}</span>
                                    <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                                </el-option>
                            </el-select> -->
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="addQuesBank">新增</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                type="index"
                                align="center"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="题库名称"
                                min-width="150"
                                prop="questionBankName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="配置类型"
                                show-overflow-tooltip
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_QUESTION_CONFIG_TYPE",row.relationType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="培训任务名称"
                                prop="projectName"
                                min-width="150"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="招生任务名称"
                                prop="ctProjectName"
                                min-width="150"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="练习期限"
                                prop="expirationDate"
                                min-width="100"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="操作人"
                                prop="createUser"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="操作日期"
                                prop="createTime"
                                min-width="150"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="操作"
                                align="center"
                                fixed="right"
                                min-width="180"
                            >
                                <template v-slot="{row}">
                                    <el-button type="text" style="padding: 0 5px;" @click="viewList(row)">查看名单</el-button>
                                    <el-button type="text" style="padding: 0 5px;" @click="delay(row)">延期</el-button>
                                    <el-button type="text" style="padding: 0 5px;" @click="toVoid(row)">作废</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
        <!-- 延期对话框 -->
        <el-dialog
            title="延期"
            width="500px"
            @closed="delayClosed"
            :visible.sync="delayDialogVisible">
            <div>
                <el-form ref="delayForm" :model="delayForm" :rules="delayRules" label-width="100px">
                    <el-form-item label="原练习期限">
                        <el-date-picker
                            :disabled="true"
                            v-model="delayForm.preData"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择原练习期限">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="新练习期限" prop="expirationDate">
                        <el-date-picker
                            v-model="delayForm.expirationDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            placeholder="选择新练习期限">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delayDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="delayOk" class="bgc-bv">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加题库对话框 -->
        <el-dialog
            title="配置练习"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            @closed="bankFormClosed"
            :visible.sync="bankDialogVisible">
            <div>
                <el-form ref="bankForm" :rules="bankFormRules" :model="bankForm" label-width="100px">
                    <el-form-item label="选择题库" prop="questionBankId">
                        <el-select
                            v-model="bankForm.questionBankId"
                            size="small"
                            :remote-method="getQuestionBankList"
                            remote
                            filterable
                            clearable
                            placeholder="请至少输入两个字搜索"
                            >
                            <el-option
                                v-for="item in questionBankList"
                                :key="item.questionBankId"
                                :label="item.questionBankName"
                                :value="item.questionBankId"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="配置类型" prop="relationType">
                        <el-select v-model="bankForm.relationType" size="small" clearable placeholder="请选择配置类型">
                            <el-option
                                v-for="item in configurationTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="bankForm.relationType=='01'" key="培训任务" label="培训任务">
                        <el-select
                            v-model="bankForm.relationId"
                            placeholder="请选择培训任务"
                            remote
                            size="small"
                            clearable
                            :remote-method="superProjectSelect"
                            @visible-change="clearProjectSearchModel">
                            <template>
                                <div class="select-header">
                                    <span style="color: #8492a6;float: left;font-size: 13px;width: 150px;">班级编号</span>
                                    <span style="color: #8492a6;float: left;font-size: 13px;margin-left: 50px;width: 250px;">班级名称</span>
                                </div>
                                <div class="select-header">
                                    <el-input
                                        v-model="trainingTaskForm.projectCode"
                                        v-on:input="superProjectSelect"
                                        type="text"
                                        size="small"
                                        placeholder="班级编号"
                                        clearable
                                        style="width: 150px"
                                    />
                                    <el-input
                                        v-model="trainingTaskForm.projectName"
                                        v-on:input="superProjectSelect"
                                        type="text"
                                        size="small"
                                        placeholder="请输入班级名称"
                                        clearable
                                        style="margin-left: 50px; width: 250px"
                                    />
                                </div>
                            </template>
                            <el-option
                                v-for="(item, index) in trainingTasksList"
                                :key="index"
                                :label="item.projectName"
                                :value="item.projectId">
                                <span style="width: 150px; font-size: 13px">{{item.projectCode}}</span>
                                <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="bankForm.relationType=='00'" key="报名列表" label="报名列表">
                        <el-select
                            v-model="bankForm.relationId"
                            filterable
                            clearable
                            remote
                            size="small"
                            placeholder="请至少输入两字搜索"
                            :remote-method="getsignUpList"
                            >
                            <el-option
                                v-for="item in signUpList"
                                :key="item.projectId"
                                :label="item.projectName"
                                :value="item.projectId"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="练习期限" prop="expirationDate">
                        <el-date-picker
                            clearable
                            v-model="bankForm.expirationDate"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="下载模板" v-if="bankForm.relationType=='02'" key="下载模板">
                        <el-button type="text" @click="downloadExcel">学员导入模板</el-button>
                    </el-form-item>
                    <el-form-item v-if="bankForm.relationType=='02'" label="选择文件" prop="file" key="选择文件">
                        <div style="display:flex;">
                            <el-upload
                                class="upload"
                                action
                                :on-change="uploadChange"
                                :auto-upload="false"
                                :show-file-list="false"
                                :limit="3">
                                <el-button 
                                    style="height:40px;"
                                    class="bgc-bv" 
                                    size="small"
                                    type="primary">
                                        点击上传
                                </el-button>
                                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                            </el-upload>
                        </div>
                        <div class="fileList">
                            <div class="file-item">
                                <div style="color:#409EFF;">{{fileList.fileName}}</div>
                                <el-button v-if="fileList.fileName" type="text" style="color:red;" @click="delFile">删除</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="错误信息" v-if="errorInfo.errorNum && bankForm.relationType=='02'">
                        <div class="errorInfo">
                            <div class="success">正确数：{{errorInfo.successNum}}</div>
                            <div class="error">错误数：{{errorInfo.errorNum}}</div>
                            <el-button size="small" type="text" @click="exportErrorStu">导出错误学员</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bankDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addQuestionBank" class="bgc-bv">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import List from "@/mixins/List.js"
    import PageNum from "@/components/PageNum.vue";
    import Empty from "@/components/Empty.vue";
    export default {
        name:"ExerciseQuestionBankConfig",
        mixins:[List],
        components:{
            PageNum,
            Empty
        },
        data(){
            const validateFile=(rule,value,callback)=>{
                const {bankForm}=this;
                if(!bankForm.excelKey){
                    callback("请上传学员导入模板");
                    return;
                }
                callback();
            }
            return{
                // 查询表单数据
                searchForm:{
                    ctProjectName:"",// 招生名称
                    projectName:"",// 班级培训名称
                    questionBankName:"",// 题库名称
                },
                // 培训任务 里面子项的查询
                searchProjectForm:{
                    projectName:"", // 班级名称
                    projectCode:"" // 班级编码
                },
                // 培训名称搜索数据
                projectList:[],
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 延期对话框可见性
                delayDialogVisible:false,
                // 配置题库对话框
                bankDialogVisible:false,
                // 列表数据
                listData:[],
                // 延期表单数据
                delayForm:{
                    preData:"",// 原时间（只读）
                    configId:"",//配置id
                    expirationDate:"",// 题库截止时间
                },
                // 添加题库表单数据
                bankForm:{
                    excelKey:"",// 上传文件的key
                    expirationDate:"",// 题库截止日期
                    questionBankId:"",// 题库id
                    relationId:"",// 关联id
                    relationType:"",// 配置类型
                },
                // 配置类型下拉数据
                configurationTypeList:[],
                // 上传文件的列表
                fileList:{},
                // 题库搜索数据
                questionBankList:[],
                // 培训任务数据
                trainingTasksList:[],
                // 培训任务搜索表单
                trainingTaskForm:{
                    projectName:"",
                    projectCode:"",
                },
                // 报名列表下拉数据
                signUpList:[],
                // 日期选择器特性
                pickerOptions:{
                    // 禁用的时间
                    disabledDate:(date)=> {
                        const {delayForm}=this;
                        const nowDate = new Date(delayForm.preData);
                        return date < nowDate;
                    },
                },
                // 延期表单校验规则
                delayRules:{
                    expirationDate:[
                        {required:true,message:"请选择新练习期限",trigger:"blur"}
                    ]
                },
                // 创建题库表单校验规则
                bankFormRules:{
                    questionBankId:[
                        {required:true,message:"请选择题库",trigger:"change"}
                    ],
                    relationType:[
                        {required:true,message:"请选择配置类型",trigger:"change"}
                    ],
                    relationId:[
                        {required:true,message:"请选择培训任务/报名列表",trigger:"change"}
                    ],
                    expirationDate:[
                        {required:true,message:"请选择练习期限",trigger:"change"}
                    ],
                    file:[
                        {validator:validateFile,trigger:"blur"}
                    ]
                },
                // 导入学员错误的信息
                errorInfo:{
                    batchId:"", // 错误id
                    errorNum:0, // 错误数
                    successNum:0 // 成功数
                }
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            initData(){
                // 获取列表数据
                this.getListData();
                // 获取配置类型字典数据
                this.getQuestionBankState();
            },
            // 获取列表数据
            getListData(current){
                const {pagination,searchForm}=this;
                if(current){
                    pagination.current=current;
                }
                this.$post("/biz/ct/questionBankConfig/pageList",{
                    pageNum:pagination.current,
                    pageSize:pagination.size,
                    ...searchForm
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                });
            },
            // 培训名称搜索方法（查询时用）
            // getProjectList(){
            //     const {searchProjectForm}=this;
            //     this.$post("/biz/project/superSelectProject",{
            //             pageNum: 1, //每次都只要第一页
            //             pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
            //             ...searchProjectForm
            //         },3000,false
            //     ).then((res) => {
            //         if(res.data.list.length === 0) {
            //             this.projectList = [{}];
            //         }else{
            //             this.projectList = res.data.list;
            //         }
            //     });
            // },
            // // 清空培训任务搜索框
            // clearProjectList(e) {
            //     const {searchProjectForm}=this;
            //     if(e){
            //         searchProjectForm.projectName="";
            //         searchProjectForm.projectCode="";
            //         this.getProjectList();
            //     }
            // },
            // 题库搜索方法
            getQuestionBankList(query) {
                if(query.trim().length<2){
                    this.questionBankList=[];
                    return;
                }
                this.$post("/biz/ct/project/selectQuestionBank",{
                    questionBankName: query 
                },3000,false).then(res => {
                    this.questionBankList = res.data || [];
                });
            },
            // 创建题库确定
            addQuestionBank(){
                this.$refs.bankForm.validate(valid=>{
                    if(valid){
                        const {bankForm}=this;
                        const params=JSON.parse(JSON.stringify(bankForm));
                        // 如果是按题库 不传relationId
                        if(params.relationType=="02"){
                            delete params.relationId;
                        }
                        this.$post("/biz/ct/questionBankConfig/addConfig",{
                            ...bankForm
                        }).then((res)=>{
                            // 当按题库时判断有没有错误学员
                            if(params.relationType=="02" && res.data.errorNum>0){
                                this.$message.error("导入学员有错误");
                                this.errorInfo=res.data;
                            }else{
                                this.$message.success("创建题库成功");
                                this.bankDialogVisible=false;
                                this.getListData();
                            }
                        });
                    }
                });
            },
            // 添加题库对话框关闭后
            bankFormClosed(){
                this.$refs.bankForm.resetFields();
                this.bankForm={
                    excelKey:"",// 上传文件的key
                    expirationDate:"",// 题库截止日期
                    questionBankId:"",// 题库id
                    relationId:"",// 关联id
                    relationType:"",// 配置类型
                }
                this.fileList={}
                this.errorInfo={
                    batchId:"", // 错误id
                    errorNum:0, // 错误数
                    successNum:0 // 成功数
                }
            },
            // 导出错误学员
            exportErrorStu(){
                const {errorInfo}=this;
                this.$post("/biz/ct/questionBankConfig/exportErrorUser",{
                    batchId:errorInfo.batchId
                }).then(res=>{
                    window.open(res.data);
                }).catch(e=>{
                    console.error("导出错误学员出错",e);
                });
            },
            // 下载学员导入模板
            downloadExcel(){
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = "/static/学员导入模板.xls";
                link.setAttribute("download", "学员导入模板.xls");
                document.body.appendChild(link);
                link.click();
            },
            // 延期
            delay(row){
                const {delayForm}=this;
                let {expirationDate,configId}=row;
                // 如果有原时间
                if(expirationDate){
                    expirationDate=expirationDate.replaceAll("/","-");
                    delayForm.preData=expirationDate;
                }
                delayForm.configId=configId;
                this.delayDialogVisible=true;
            },
            // 延期确定
            delayOk(){
                const {delayForm}=this;
                this.$refs.delayForm.validate(valid=>{
                    if(valid){
                        this.$post("/biz/ct/questionBankConfig/postpone",delayForm).then(res=>{
                            this.$message.success("延期成功");
                            this.delayDialogVisible=false;
                            this.getListData();
                        });
                    }
                });
            },
            // 延期对话框关闭后回调
            delayClosed(){
                this.$refs.delayForm.resetFields();
                this.delayForm={
                    reData:"",// 原时间（只读）
                    configId:"",//配置id
                    expirationDate:"",// 题库截止时间
                }
            },
            // 添加题库
            addQuesBank(){
                this.bankDialogVisible=true;
            },
            // 上传文件钩子改变
            uploadChange(file){
                const size = file.size / 1024 / 1024;
                const fileName=file.name;
                const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
                const fileTypeList=["xls","xlsx"];
                if (size>5) {
                    this.$message.error("文件大小不能超过5MB");
                    return;
                }
                if(!fileTypeList.includes(extension)){
                    this.$message.error("只能上传xls/xlsx文件");
                    return;
                }
                const formData = new FormData();
                formData.append("folder","QUESTION");
                formData.append("file",file.raw);
                formData.append("fileType",extension);
                this.$Postformat("/sys/upload", formData).then((res)=>{
                    this.fileList={
                        ...res.data,
                        fileName
                    }
                    this.bankForm.excelKey=res.data.fileKey;
                });
            },
            // 获取报名列表下拉数据
            getsignUpList(query){
                if(query.trim().length<2){
                    this.signUpList=[];
                    return;
                }
                this.$post("/biz/ct/project/ctProjectListNoPage",{
                    projectName:query
                },3000,false).then(res=>{
                    this.signUpList=res?.data||[];
                });
            },
            // 删除学员导入模板文件
            delFile(){
                const {bankForm}=this;
                bankForm.excelKey="";
                this.fileList={}
            },
            // 查看名单
            viewList(row){
                this.$router.push({
                    path:"/web/operate/viewList",
                    query:{
                        configId:row.configId
                    }
                });
            },
            // 作废
            toVoid(row){
                const {configId}=row;
                this.$confirm('确定作废此题库配置?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$post("/biz/ct/questionBankConfig/disabled",{
                        configId
                    }).then(()=>{
                        this.$message.success("操作成功");
                        this.getListData();
                    });
                });
            },
            //获取培训任务下拉数据（创建题库时候用）
            superProjectSelect() {
                const {trainingTaskForm}=this;
                this.$post("/biz/project/superSelectProject",{
                        pageNum: 1, //每次都只要第一页
                        pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
                        ...trainingTaskForm
                    },3000,false
                ).then((res) => {
                    if(res.data.list.length === 0) {
                        this.trainingTasksList = [{}];
                    }else{
                        this.trainingTasksList = res.data.list;
                    }
                });
            },
            // 下拉框出现/隐藏时触发
            clearProjectSearchModel(e) {
                const {trainingTaskForm}=this;
                if(e){
                    trainingTaskForm.projectName="";
                    trainingTaskForm.projectCode="";
                    this.superProjectSelect();
                }
            },
            // 获取配置类型字典数据
            getQuestionBankState(){
                const obj=this.$setDictionary("CT_QUESTION_CONFIG_TYPE", "list")||{}
                const arr=[];
                Object.keys(obj).forEach(key=>{
                    arr.push({
                        label:obj[key],
                        value:key
                    });
                });
                this.configurationTypeList=arr;
            },
            // 当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getListData();
            },
            // 当前页每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getListData();
            },
            // 获取表格高度
            getTableHeight() {
                this.tableHeight = window.innerHeight - 260;
            },
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">
    /deep/.upload{
        height: 40px !important;
        .el-upload{
            height: 40px !important;
            border: none;
        }
    }
    // 上传文件列表样式
    .fileList{
        margin-top: 40px;
        .file-item{
            display: flex;
            .el-button{
                margin-left: 10px;
            }
        }
    }
    // 培训任务选择框样式
    .select-header {
        font-size: 14px;
        padding: 0 20px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606266;
        height: 34px;
        line-height: 34px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
    }
    // 错误信息样式
    .errorInfo{
        display: flex;
        .success{
            color: #5C6BE8;
        }
        .error{
            margin-left: 20px;
            color: red;
        }
        .el-button{
            margin-left:20px
        }
    }
</style>